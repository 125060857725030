import { ChartIntervalSelector } from "../components/ChartIntervalSelector";
import { Title } from "../components/Element";
import { LatestSensorView } from "../components/LatestSensorView";
import { PastSensorSelector } from "../components/PastSensorSelector";
import { PastSensorView } from "../components/PastSensorView";
import { UnitSelector } from "../components/UnitSelector";
import { UserInfo } from "../components/UserInfo";

export const Home = () => {
  return (
    <div>
      <Title.H1>エムアール環境計画 センサービューワー</Title.H1>

      <div className="my-8">
        <p>水槽選択</p>
        <div className="my-2">
          <UnitSelector />
        </div>
      </div>

      <div className="my-12">
        <Title.H2>最新情報</Title.H2>

        <div className="mt-4">
          <LatestSensorView />
        </div>
      </div>

      <div className="my-12">
        <Title.H2>過去データ</Title.H2>

        <div className="flex gap-4 my-4">
          <PastSensorSelector />

          <ChartIntervalSelector />
        </div>

        <PastSensorView />

        <div className="my-8">
          <Title.H2>ログイン情報</Title.H2>

          <div className="mt-4">
            <UserInfo />
          </div>
        </div>
      </div>
    </div>
  );
};
