import { axiosX } from "../libs/axios";
import { createContext, ReactNode, useEffect, useState } from "react";

export type AuthTypes = {
  userId: number;
  loginId: string;
};

/**
 * Auth Hook
 */
const useAuth = () => {
  const [currentAuth, setCurrentAuth] = useState<AuthTypes | null>();
  const [updater, setUpdater] = useState(0);

  // update current auth
  const update = () => {
    setUpdater(updater + 1);
  };

  // Logout
  const logout = async () => {
    try {
      await axiosX.post("/auth/logout");

      setCurrentAuth(null);

      return true;
    } catch (err) {
      console.log(err);

      return false;
    }
  };

  // Login
  const login = async (loginId: string, password: string): Promise<boolean> => {
    try {
      const postData = {
        loginId,
        password,
      };

      await axiosX.post("/auth/login", postData);
      update();

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  // get current
  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await axiosX.get<AuthTypes>("/auth/verify");

        if (data) {
          const auth: AuthTypes = {
            userId: data.userId,
            loginId: data.loginId,
          };

          setCurrentAuth(auth);
        } else {
          setCurrentAuth(null);
        }
      } catch (err) {
        setCurrentAuth(null);
      }
    };

    getUser();
  }, [updater]);

  return { currentAuth, update, logout, login };
};

/**
 * Context
 */
type AuthContextType = {
  currentAuth: AuthTypes | null | undefined;
  update: () => void;
  logout: () => Promise<boolean>;
  login: (loginId: string, password: string) => Promise<boolean>;
};

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = (props: { children: ReactNode }) => {
  const { currentAuth, update, logout, login } = useAuth();

  return (
    <AuthContext.Provider value={{ currentAuth, update, logout, login }}>
      {props.children}
    </AuthContext.Provider>
  );
};
