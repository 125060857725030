import { ButtonHTMLAttributes, FC, ReactNode } from "react";

/* Container ----------------------------------- */
const Page = ({ children }: { children?: ReactNode }) => {
  return <div className="mx-auto py-4 w-11/12 max-w-screen-lg">{children}</div>;
};

export const Container = { Page };

/* Title ----------------------------------- */

type TitleProps = {
  children?: ReactNode;
};

const H1: FC<TitleProps> = ({ children }) => {
  return <h1 className="text-3xl">{children}</h1>;
};

const H2: FC<TitleProps> = ({ children }) => {
  return <h2 className="text-2xl">{children}</h2>;
};

const H3: FC<TitleProps> = ({ children }) => {
  return <h3 className="text-xl">{children}</h3>;
};

const Sub: FC<TitleProps> = ({ children }) => (
  <p className="mt-2 text-lg">{children}</p>
);

export const Title = { H1, H2, H3, Sub };

/* Button ----------------------------------- */
const Normal: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button
      className="px-4 py-2 bg-slate-700 hover:bg-slate-500 active:bg-slate-900 text-slate-50 rounded-md"
      {...props}
    >
      {children}
    </button>
  );
};

export const Button = { Normal };

/* Texts ----------------------------------- */
export const LoadingText = () => <p>ロード中...</p>;

export const NoDataText = () => <p>データがありません</p>;
