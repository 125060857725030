import {
  FC,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Button, Title } from "../components/Element";
import { AuthContext } from "../contexts/AuthContext";

const InputContainer: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex gap-x-2 my-4 items-center">{children}</div>
);

const Label: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  ...props
}) => (
  <label className="block w-24" {...props}>
    {children}
  </label>
);

const Input: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  ...props
}) => <input {...props} />;

export const Login = () => {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { currentAuth, login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSending(true);
    setMessage("ログイン中...");

    try {
      const result = await login(loginId, password);

      if (result) {
        setMessage("ログイン成功");
      } else {
        setMessage("ログインに失敗しました");
      }
    } catch (error) {
      console.log(error);

      setMessage(
        "予期せぬエラーが発生しました。時間をおいて再度お試しください"
      );
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (currentAuth) {
      navigate("/");
    }
  }, [currentAuth, navigate]);

  return (
    <div>
      <Title.H1>ログイン</Title.H1>
      <Title.Sub>エムアール環境計画 センサービューワー</Title.Sub>

      <form className="my-8" onSubmit={handleSubmit}>
        <InputContainer>
          <Label htmlFor="loginId">ユーザー</Label>
          <Input
            type="text"
            id="loginId"
            disabled={isSending}
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <Label htmlFor="password">パスワード</Label>
          <Input
            type="password"
            id="password"
            disabled={isSending}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>

        <div className="my-8">
          <Button.Normal type="submit" disabled={isSending}>
            送信
          </Button.Normal>
        </div>
        <p className="mt-4 text-red-600">{message}</p>
      </form>
    </div>
  );
};
