import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CHART_INTERVAL,
  ChartIntervalTypes,
  SENSOR,
  SensorTypes,
} from "../app.const";

export type ViewModeTypes = "personal" | "all";

type AppState = {
  unit: number;
  sensor: SensorTypes;
  chartInterval: ChartIntervalTypes;
};

const initialState: AppState = {
  unit: 1,
  sensor: SENSOR.TEMPERATURE4,
  chartInterval: CHART_INTERVAL.HOUR,
};

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setUnit: (state, action: PayloadAction<number>) => {
      state.unit = action.payload;
    },
    setSensor: (state, action: PayloadAction<SensorTypes>) => {
      state.sensor = action.payload;
    },
    setChartInterval: (state, action: PayloadAction<ChartIntervalTypes>) => {
      state.chartInterval = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const { setUnit, setSensor, setChartInterval } = appSlice.actions;
