import { useEffect, useState } from "react";
import { ChartIntervalTypes, SensorTypes } from "../app.const";
import { axiosX } from "../libs/axios";

type SensorOtherValueDbTypes = "err1" | "err2" | "none";

export type SensorLatestTypes = {
  sensorId: number;
  unit: number;
  temperature4: number | SensorOtherValueDbTypes;
  ph: number | SensorOtherValueDbTypes;
  dissolvedOxygen: number | SensorOtherValueDbTypes;
  salt: number | SensorOtherValueDbTypes;
  temperatureRoom: number | SensorOtherValueDbTypes;
  humidity: number | SensorOtherValueDbTypes;
  createdAt: Date;
};

export type SensorLatestApiTypes = {
  sensorId: number;
  unit: number;
  temperature4: number | SensorOtherValueDbTypes;
  ph: number | SensorOtherValueDbTypes;
  dissolvedOxygen: number | SensorOtherValueDbTypes;
  salt: number | SensorOtherValueDbTypes;
  temperatureRoom: number | SensorOtherValueDbTypes;
  humidity: number | SensorOtherValueDbTypes;
  createdAt: string;
};

export const useSensorLatest = (unit: number) => {
  const [sensor, setSensor] = useState<SensorLatestTypes | null>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axiosX.get<SensorLatestApiTypes>(
          "/sensor/latest",
          {
            params: {
              unit: unit,
            },
          }
        );

        if (data) {
          const fetchedSensor: SensorLatestTypes = {
            ...data,
            createdAt: new Date(data.createdAt),
          };

          console.log(fetchedSensor);

          setSensor(fetchedSensor);
        } else {
          setSensor(null);
        }
      } catch (err) {
        console.error(err);

        setSensor(null);
      }
    };

    fetch();
  }, [unit]);

  return { sensor };
};

/* ----------------------------------- */

export type SensorValuesApiTypes = {
  time: string[];
  data: (number | null)[];
};

export const useSensorValuesPast = (
  unit: number,
  interval: ChartIntervalTypes,
  sensor: SensorTypes,
  take: number
) => {
  const [sensorValues, setSensorValues] =
    useState<SensorValuesApiTypes | null>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axiosX.get<SensorValuesApiTypes>(
          "/sensors/past",
          {
            params: {
              unit: unit,
              interval: interval,
              sensor: sensor,
              take: take,
            },
          }
        );

        if (data.data.length > 0 && data.time.length > 0) {
          const fetchedSensorData: SensorValuesApiTypes = {
            time: data.time.reverse(),
            data: data.data.reverse(),
          };

          setSensorValues(fetchedSensorData);
        } else {
          setSensorValues(null);
        }
      } catch (err) {
        console.error(err);

        setSensorValues(null);
      }
    };

    fetch();
  }, [unit, interval, sensor, take]);

  return { sensorValues };
};
