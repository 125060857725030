import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Container } from "./components/Element";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";

const router = createBrowserRouter([
  {
    element: <ProtectedLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export const App = () => {
  return (
    <div className="min-h-screen text-slate-900 bg-slate-50">
      <Container.Page>
        <RouterProvider router={router} />
      </Container.Page>
    </div>
  );
};
