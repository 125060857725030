import { format, subHours } from "date-fns";

import { FC, ReactNode } from "react";
import { useSensorLatest } from "../hooks/useSensor";
import { useAppSelector } from "../stores/storeHooks";
import { LoadingText, NoDataText } from "./Element";

const Dl: FC<{ children: ReactNode }> = ({ children }) => (
  <dl className="flex my-2">{children}</dl>
);

const Dt: FC<{ children: ReactNode }> = ({ children }) => (
  <dt className="mr-8 w-32 font-bold">{children}</dt>
);

const Dd: FC<{ children: ReactNode }> = ({ children }) => (
  <dd className="text-base">{children}</dd>
);

export const LatestSensorView = () => {
  const { unit } = useAppSelector((state) => state.app);
  const { sensor } = useSensorLatest(unit);

  if (sensor === undefined) {
    return <LoadingText />;
  }

  if (sensor === null) {
    return <NoDataText />;
  }

  const dateUpdated = format(
    subHours(sensor.createdAt, 9),
    "yyyy/MM/dd HH:mm:ss"
  );

  return (
    <div>
      <Dl>
        <Dt>更新日時</Dt>
        <Dd>{dateUpdated}</Dd>
      </Dl>
      {/* <Dl>
        <Dt>ID</Dt>
        <Dd>{sensor.sensorId}</Dd>
      </Dl>
      <Dl>
        <Dt>センサーユニット</Dt>
        <Dd>{sensor.unit}</Dd>
      </Dl> */}
      <Dl>
        <Dt>温度</Dt>
        <Dd>{sensor.temperature4} ℃</Dd>
      </Dl>
      <Dl>
        <Dt>pH</Dt>
        <Dd>{sensor.ph} pH</Dd>
      </Dl>
      <Dl>
        <Dt>溶存酸素量</Dt>
        <Dd>{sensor.dissolvedOxygen} mg/L</Dd>
      </Dl>

      {unit === 1 && (
        <>
          <Dl>
            <Dt>塩分濃度</Dt>
            <Dd>{sensor.salt} %</Dd>
          </Dl>
          <Dl>
            <Dt>室温</Dt>
            <Dd>{sensor.temperatureRoom} ℃</Dd>
          </Dl>
          <Dl>
            <Dt>湿度</Dt>
            <Dd>{sensor.humidity} %</Dd>
          </Dl>
        </>
      )}
    </div>
  );
};
