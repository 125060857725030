export const SENSOR = {
  TEMPERATURE4: "temperature4",
  PH: "ph",
  DISSOLVED_OXYGEN: "dissolvedOxygen",
  SALT: "salt",
  TEMPERATURE_ROOM: "temperatureRoom",
  HUMIDITY: "humidity",
} as const;

export type SensorTypes = typeof SENSOR[keyof typeof SENSOR];

export const CHART_INTERVAL = {
  HOUR: "hour",
  TWO_HOURS: "twoHours",
  DAY: "day",
  WEEK: "week",
  TWO_WEEKS: "twoWeeks",
  MONTH: "month",
} as const;

export type ChartIntervalTypes =
  typeof CHART_INTERVAL[keyof typeof CHART_INTERVAL];
