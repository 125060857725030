import { ChangeEvent } from "react";
import { setUnit } from "../stores/appSlice";
import { useAppDispatch, useAppSelector } from "../stores/storeHooks";

export const UnitSelector = () => {
  const { unit } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newUnit = Number(e.target.value as string);

    dispatch(setUnit(newUnit));
  };

  return (
    <div>
      <select value={unit} onChange={handleChange}>
        <option value={1}>水槽1</option>
        <option value={2}>水槽2</option>
        <option value={3}>水槽3</option>
        <option value={4}>水槽4</option>
        <option value={5}>水槽5</option>
      </select>
    </div>
  );
};
