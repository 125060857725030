import { FC } from "react";
import { Line } from "react-chartjs-2";
import { SENSOR } from "../../app.const";
import { useSensorValuesPast } from "../../hooks/useSensor";
import { formatTimeLabels } from "../../libs/chart";
import { useAppSelector } from "../../stores/storeHooks";
import { LoadingText, NoDataText } from "../Element";

export const DissolvedOxygenChart: FC<{
  dataLabel?: string;
  color?: string;
}> = ({ dataLabel = "溶存酸素量", color = "#5f7" }) => {
  const { unit, chartInterval } = useAppSelector((state) => state.app);
  const { sensorValues } = useSensorValuesPast(
    unit,
    chartInterval,
    SENSOR.DISSOLVED_OXYGEN,
    12
  );

  if (sensorValues === undefined) return <LoadingText />;
  if (sensorValues === null) return <NoDataText />;

  const labels = formatTimeLabels(sensorValues.time, chartInterval);

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "mg/L",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  const data = {
    options: chartOptions,
    labels,
    datasets: [
      {
        label: dataLabel,
        data: sensorValues.data,
        backgroundColor: color,
        borderColor: color,
      },
    ],
  };

  return (
    <div>
      <Line options={chartOptions} data={data} />
    </div>
  );
};
