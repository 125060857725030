import { ChangeEvent, useEffect } from "react";
import { SENSOR, SensorTypes } from "../app.const";
import { setSensor } from "../stores/appSlice";
import { useAppDispatch, useAppSelector } from "../stores/storeHooks";

export const PastSensorSelector = () => {
  const { unit, sensor } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newSensor = e.target.value as SensorTypes;

    dispatch(setSensor(newSensor));
  };

  useEffect(() => {
    if (unit !== 1) {
      if (sensor === SENSOR.SALT || sensor === SENSOR.HUMIDITY) {
        dispatch(setSensor(SENSOR.TEMPERATURE4));
      }
    }
  }, [unit, sensor, dispatch]);

  const temperatureText = unit === 1 ? "水温・室温" : "水温";

  return (
    <div>
      <select onChange={handleChange}>
        <option value={SENSOR.TEMPERATURE4}>{temperatureText}</option>
        <option value={SENSOR.PH}>pH</option>
        <option value={SENSOR.DISSOLVED_OXYGEN}>溶存酸素量</option>

        {unit === 1 && (
          <>
            <option value={SENSOR.SALT}>塩分濃度</option>
            <option value={SENSOR.HUMIDITY}>湿度</option>
          </>
        )}
      </select>
    </div>
  );
};
