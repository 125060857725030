import { useContext } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export const ProtectedLayout = () => {
  const { currentAuth } = useContext(AuthContext);
  const outlet = useOutlet();

  if (currentAuth === undefined) {
    return <p>loading...</p>;
  }

  if (!currentAuth) {
    return <Navigate to="/login" />;
  }

  return outlet;
};
