import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { format, subHours } from "date-fns";
import { ChartIntervalTypes, CHART_INTERVAL } from "../app.const";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.aspectRatio = 1.6;

export const formatTimeLabels = (
  times: string[],
  chartInterval: ChartIntervalTypes
) => {
  return times.map((time) => {
    const labelDate = subHours(new Date(time), 9);

    switch (chartInterval) {
      case CHART_INTERVAL.HOUR:
      case CHART_INTERVAL.TWO_HOURS:
        return format(labelDate, "d日 HH:mm");
      case CHART_INTERVAL.DAY:
        return format(labelDate, "M/d");
      case CHART_INTERVAL.WEEK:
        return format(labelDate, "M/d〜");
      case CHART_INTERVAL.TWO_WEEKS:
        return format(labelDate, "M/d〜");
      case CHART_INTERVAL.MONTH:
        return format(labelDate, "yyyy年M月");
      default:
        return time;
    }
  });
};
