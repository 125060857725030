import { FC } from "react";
import { Line } from "react-chartjs-2";
import { SENSOR } from "../../app.const";
import { useSensorValuesPast } from "../../hooks/useSensor";
import { formatTimeLabels } from "../../libs/chart";
import { useAppSelector } from "../../stores/storeHooks";
import { LoadingText, NoDataText } from "../Element";

export const TemperatureChart: FC<{}> = () => {
  const { unit, chartInterval } = useAppSelector((state) => state.app);
  const { sensorValues: sensorWaterValues } = useSensorValuesPast(
    unit,
    chartInterval,
    SENSOR.TEMPERATURE4,
    12
  );
  const { sensorValues: sensorRoomValues } = useSensorValuesPast(
    unit,
    chartInterval,
    SENSOR.TEMPERATURE_ROOM,
    12
  );

  if (sensorWaterValues === undefined) return <LoadingText />;
  if (sensorWaterValues === null) return <NoDataText />;

  const labels = formatTimeLabels(sensorWaterValues.time, chartInterval);

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "℃",
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "水温",
        data: sensorWaterValues.data,
        backgroundColor: "#7af",
        borderColor: "#7af",
      },
    ],
  };

  if (unit === 1 && sensorRoomValues) {
    data.datasets.push({
      label: "室温",
      data: sensorRoomValues.data,
      backgroundColor: "#fa7",
      borderColor: "#fa7",
    });
  }

  return (
    <div>
      <Line options={chartOptions} data={data} />
    </div>
  );
};
