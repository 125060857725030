import { ChangeEvent } from "react";
import { ChartIntervalTypes, CHART_INTERVAL } from "../app.const";
import { setChartInterval } from "../stores/appSlice";
import { useAppDispatch } from "../stores/storeHooks";

export const ChartIntervalSelector = () => {
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newInterval = e.target.value as ChartIntervalTypes;

    dispatch(setChartInterval(newInterval));
  };

  return (
    <div>
      <div>
        <select onChange={handleChange}>
          <option value={CHART_INTERVAL.HOUR}>1時間ごと</option>
          <option value={CHART_INTERVAL.TWO_HOURS}>2時間ごと</option>
          <option value={CHART_INTERVAL.DAY}>1日ごと</option>
          <option value={CHART_INTERVAL.WEEK}>1週間ごと</option>
          <option value={CHART_INTERVAL.TWO_WEEKS}>2週間ごと</option>
          {/* <option value={CHART_INTERVAL.MONTH}>1月ごと</option> */}
        </select>
      </div>
    </div>
  );
};
