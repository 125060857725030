import { useEffect, useRef, useState } from "react";
import { SENSOR } from "../app.const";
import { useAppSelector } from "../stores/storeHooks";
import { DissolvedOxygenChart } from "./charts/DissolvedOxygenChart";
import { HumidityChart } from "./charts/HumidityChart";
import { PhChart } from "./charts/PhChart";
import { SaltChart } from "./charts/SaltChart";
import { TemperatureChart } from "./charts/TemperatureChart";

const ASPECT = 0.625;

export const PastSensorView = () => {
  const { sensor } = useAppSelector((state) => state.app);
  const [minHeight, setMinHeight] = useState(window.innerHeight);
  const wrapperRef = useRef<HTMLDivElement>(null);

  let chart = null;

  switch (sensor) {
    case SENSOR.TEMPERATURE4:
      chart = <TemperatureChart />;
      break;
    case SENSOR.PH:
      chart = <PhChart />;
      break;
    case SENSOR.DISSOLVED_OXYGEN:
      chart = <DissolvedOxygenChart />;
      break;
    case SENSOR.SALT:
      chart = <SaltChart />;
      break;
    case SENSOR.HUMIDITY:
      chart = <HumidityChart />;
      break;
  }

  useEffect(() => {
    if (wrapperRef.current) {
      const minHeight = wrapperRef.current.clientWidth * ASPECT + 16;

      setMinHeight(minHeight);
    }
  }, [wrapperRef]);

  if (!chart) return null;

  return (
    <div ref={wrapperRef} style={{ minHeight: `${minHeight}px` }}>
      {chart}
    </div>
  );
};
